
import { defineComponent, ref } from "vue";
import AddressCard from "@/components/common/AddressCard.vue";
import axios from "@/api/axios";
import qs from "qs";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "Address",
  components: {
    AddressCard,
  },
  setup() {

    const list = ref<object>([]);



    function getList() {
      axios.get("/M/User/Address").then(res => {

          list.value = res.data.obj.list
        console.log(list.value)

      }).catch(err => {
          console.log(err)
      })
    }

    getList();


    return {
      list,

    }
  },
});
