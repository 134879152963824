<template>
  <transition-group tag="ul" name="list" class="form"  v-if="list && list.length>0">
    <li class="item-wapper" :ref="addressCard" v-for="(item,index) in list" :key="index">
      <a href="javaScript:;" class="item-content">
        <div class="address-title" >Address</div>

        <div class="item address-item">
            <div style="margin-right: 0.5333rem;">
              {{ item.receiver }}
              <span style="margin-left: 0.5333rem;">{{ item.contactTel }}</span>
            </div>
            <router-link class="address-edit"  :to="{ name: 'addAddress', query: { id: item.id, addressType: item.addressType } }">
              Edit
            </router-link>
        </div>
        <div class="item" v-if="item.id">
          <div class="left tip address-detail">
            address:{{ item.allAddressInfo }}
          </div>
        </div>
      </a>
    </li>
  </transition-group>
  <transition-group tag="ul" name="list" class="form" v-else>
        <li class="item-wapper" :ref="addressCard">
          <a href="javaScript:;" class="item-content">
            <div class="address-title" >Address</div>
            <div class="item address-item">
                <div style="color: #999;">No address, please add</div>
                <router-link class="address-edit" :to="{ name: 'addAddress', query: { id: 0, addressType: 0 } }">
                  Add
                </router-link>
            </div>

          </a>
        </li>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent, watch, nextTick } from "vue";
import useAddress from "@/hooks/useAddress";
export default defineComponent({


  name: "addressCard",
  emit: ['handle-del', 'handle-edit'],
  props: {
    list: {
      type: Array,
      default: [] as object[]
    },
  },
  setup(props, ctx) {
    
    const refs: Array<HTMLElement> = [];
    
    const addressCard = (el: HTMLElement) => {
        refs.push(el);
    }

    watch(() => props.list, ()=>{


            nextTick(() => {
              // console.log(props.list)

              // eslint-disable-next-line vue/no-mutating-props
              // props.list.splice(1,2)
              // console.log(props.list)
              // console.log(useAddress)
            })

    })

    function delAddress(id: string){
      ctx.emit("handle-del", id);
    }

    function editAddress(id: string) {
      ctx.emit("handle-edit", id);
    }
    
    return {
      addressCard,
      delAddress,
      editAddress
    };
  },
});
</script>

<style lang="scss" scoped>
.item-wapper{
  width: 375px;

  height: auto;
  box-sizing: border-box;
  border-bottom: 7.5px solid #f4f4f4;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}


.form .item-content{

  color: #444444;
  font-size: 15px;
  display: block;
  width: 100%;
  height: 100%;
  // padding: 15px;
  overflow: hidden;
  box-sizing: border-box;
}

.item-content{
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: translateX(130.013px);
  transform: translateX(130.013px);

  width: 100%;
  margin-right: 0;
  margin-left: -130.013px;
  padding: 0 15px;
  box-sizing: border-box;
}


.edit{
  width: 64.987px;
  float: left;
  height: 90px;
  background: #b9b9b9;
  color: #fff;
  font-size: 12px;
  line-height: 90px;
  text-align: center;
}

.del{
  width: 64.987px;
  float: right;
  height: 90px;
  background: #d64b4b;
  color: #fff;
  font-size: 12px;
  line-height: 90px;
  text-align: center;
}


.moving{
  width: 375px;
  height: auto;
  box-sizing: border-box;
  border-bottom: 7.5px solid #f4f4f4;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
}

ul li{

  border-bottom: 6px solid #f4f4f4;
}

.item{
  overflow: hidden;
  // margin: 15px 0;
  margin: 12px 0;

}
.item .address-detail{
  width: 9.2rem;
}


.item .left{
  float: left;

}

.address-title {
  font-weight: bold;
  font-size: 16px;
  position: relative;
  padding-left: 13px;
}

.address-title::before {
  content: '';
  display: block;
  position: absolute;
  height: 20px;
  width: 5px;
  background-color: #d64b4b;
  left: 0;
}

.address-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-edit {
  float: right;
  color: #d64b4b;
    font-weight: bold;
}


.tip{
  font-size: 13.012px;
  // color: #cccccc;
  color: #666;
}
.item .right{
  float: left;
  margin-left: 19.988px;
  color: #999;
}

.default{
  position: absolute;
  right: 19.988px;
  top: 50%;
  transform: translateY(-50%);
  width: 64.987px;
    height: 25.013px;
    border-radius: 12.488px;
    border: solid 1px #999999;
    line-height: 25.013px;
    text-align: center;
    font-size: 13.988px;
    color: #fff;
    background-color: #999999;
    display: inline-block;
}

.item img{
  width: 6px;
  height: 9.488px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.submit-order-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 9px;
  height: 16px;
  margin-top: -8px;
}

.submit-order-icon img {
  width: 9px;
  height: 16px;
}


</style>
