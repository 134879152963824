
import { defineComponent, watch, nextTick } from "vue";
import useAddress from "@/hooks/useAddress";
export default defineComponent({


  name: "addressCard",
  emit: ['handle-del', 'handle-edit'],
  props: {
    list: {
      type: Array,
      default: [] as object[]
    },
  },
  setup(props, ctx) {
    
    const refs: Array<HTMLElement> = [];
    
    const addressCard = (el: HTMLElement) => {
        refs.push(el);
    }

    watch(() => props.list, ()=>{


            nextTick(() => {
              // console.log(props.list)

              // eslint-disable-next-line vue/no-mutating-props
              // props.list.splice(1,2)
              // console.log(props.list)
              // console.log(useAddress)
            })

    })

    function delAddress(id: string){
      ctx.emit("handle-del", id);
    }

    function editAddress(id: string) {
      ctx.emit("handle-edit", id);
    }
    
    return {
      addressCard,
      delAddress,
      editAddress
    };
  },
});
