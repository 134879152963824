<template>
  <address-card :list="list"></address-card>
<!--  <router-link class="out-login" :to="{ name: 'addAddress' }">-->
<!--    新增地址-->
<!--  </router-link>-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import AddressCard from "@/components/common/AddressCard.vue";
import axios from "@/api/axios";
import qs from "qs";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "Address",
  components: {
    AddressCard,
  },
  setup() {

    const list = ref<object>([]);



    function getList() {
      axios.get("/M/User/Address").then(res => {

          list.value = res.data.obj.list
        console.log(list.value)

      }).catch(err => {
          console.log(err)
      })
    }

    getList();


    return {
      list,

    }
  },
});
</script>

<style lang="scss" scoped>
.out-login {
    width: 345px;
    display: block;
    margin: 30px auto;
    height: 45px;
    background-color: #d64b4b;
    box-shadow: 0px 4.988px 10.013px 0px rgba(0, 0, 0, 0.3);
    border-radius: 3.75px;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    font-size: 15px;
}
</style>
